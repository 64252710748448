<template>
  <div>
    <!-- <Sidebar /> -->
    <StoreImage :store_detail="detailStore" />

    <div class="ps-2">
      <!-- <Username /> -->
      <HeaderPage />
    </div>
    <div style="margin-bottom: 5em">
      <!-- <HeaderNavigasi :link="links" /> -->

      <div class="title-category pt-4">
        <p class="ps-3 ml-2 text_title">{{ shopCategoryName.category_name }}</p>
      </div>

      <v-container>
        <div class="d-flex flex-row">
          <p @click.prevent="backToStore()" class="title-store">
            {{ detailStore.store_name }}
          </p>
          <v-icon> mdi-chevron-right </v-icon>
          {{ shopCategoryName.category_name }}
        </div>
        <!-- <v-spacer></v-spacer> -->

        <div class="mt-4 mb-10">
          <div v-if="isLoading">
            <v-row
              style="overflow-x: auto; flex-wrap: nowrap"
              class="row-scroll"
            >
              <v-col
                v-for="i in 10"
                cols="4"
                md="2"
                lg="2"
                sm="2"
                :key="i"
                style="padding: 0px 4px 0px 4px"
              >
                <Skeleton />
              </v-col>
            </v-row>
          </div>

          <div v-else>
            <div
              v-if="shopCategoryItems.length < 1"
              class="message-no-item-category"
            >
              {{ $t("message.no-have-category-product") }}
            </div>
            <div v-else>
              <v-row style="padding: 0px">
                <v-col
                  v-for="(item, i) in shopCategoryItems"
                  :key="i"
                  cols="4"
                  md="2"
                  lg="2"
                  sm="2"
                  align-self="center"
                  style="padding: 5px 4px 10px 4px"
                >
                  <Product :data="item" @data-slug="getModal" />
                </v-col>
              </v-row>
              <v-pagination
                v-if="!isLength"
                color="#ff0090"
                v-model="pageCategoryByStore"
                :total-visible="5"
                :length="categoryByStoreHeader.last_page"
                @input="nextpageCategoryByStore"
                style="margin-top: 10px"
              ></v-pagination>
            </div>
          </div>

          <DetailModal
            :item_slug="slug"
            :showDialog="dialog"
            @get-modal="handleModal"
            @dialogVisible="dialog"
          />

          <ConfirmationProduct
            :data_product="data_dialog_confirm"
            :showDialog="dialog_confirmation_product"
            @close="getCloseModal"
            @dialogVisible="dialog_confirmation_product"
          />

          <Warning
            :openDialog="dialog_warning"
            @handle-dialog="handleDialog_warning"
          />

          <!-- <div
            class="d-flex flex-row align-end justify-end mr-2"
            @click.prevent="$router.go(-1)"
          >
            <v-icon size="30" color="black" class="p-0 m-0">
              mdi-chevron-left
            </v-icon>
            {{ $t("message.back") }}
          </div> -->
        </div>

        <CategoryFooter />
      </v-container>
    </div>
    <Cashier />
    <Sidebar />
  </div>
</template>

<script>
import CategoryFooter from "../../components/CategoryFooter.vue";
import Product from "../../components/Product.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import User from "../../components/User.vue";
import StoreImage from "../../components/StoreImage.vue";
import Sidebar from "../../components/developmentv2/Sidebar.vue";
import Cashier from "../../components/Cashier.vue";
import HeaderPage from "../../components/developmentv2/HeaderPage.vue";
import DetailModal from "../../components/DetailModal.vue";
import ConfirmationProduct from "../../components/ConfirmationProduct.vue";
import Warning from "../../components/Warning.vue";
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
import Skeleton from "../../components/skeleton/Skeleton.vue";
export default {
  name: "ShopItemsCategory",
  components: {
    Sidebar,
    StoreImage,
    Username: User,
    HeaderNavigasi,
    Product,
    CategoryFooter,
    Cashier,
    HeaderPage,
    DetailModal,
    ConfirmationProduct,
    Warning,
    Skeleton,
  },
  data() {
    return {
      idStore: "",
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
      isLoading: true,
      pageCategoryByStore: 1,
    };
  },
  methods: {
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else if (data[0] == "dialog_alcohol") {
        this.data_dialog_warning = data[1];
        this.dialog_warning = true;
      } else {
        this.dialog = false;
      }
    },
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleDialog_warning(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.data_dialog_warning.id,
          storeId: this.data_dialog_warning.store_id,
          message: {
            title: this.message.title,
          },
        });
        setTimeout(() => {
          this.dialog = false;
        }, 1800);
      }
      this.dialog_warning = false;
    },
    async dispatchStore() {
      this.idStore = this.$router.history.current.params.id;
      let id = this.$router.history.current.params.id;
      this.$store.dispatch("shop_module/fetchStore", { id: id });
    },
    async dispatchStoreCategoryItems() {
      this.isLoading = true;
      let slug = this.$router.history.current.params.slug;
      let res = await this.$store.dispatch(
        "itemList_module/categoryDetailByStore",
        {
          slug: slug,
          id: this.idStore,
          page: 1,
        }
      );
      this.isLoading = false;
      return res;
    },
    async nextpageCategoryByStore() {
      let slug = this.$router.history.current.params.slug;
      await this.$store.dispatch("itemList_module/categoryDetailByStore", {
        slug: slug,
        id: this.idStore,
        page: this.pageCategoryByStore,
      });
    },
    backToStore() {
      let id = this.$router.history.current.params.id;
      this.$router.push(`/store/${id}`);
    },
  },
  computed: {
    detailStore() {
      return this.$store.state.shop_module.store;
    },
    shopCategoryName() {
      return this.$store.state.itemList_module.shopCategoryName;
    },
    shopCategoryItems() {
      return this.$store.state.itemList_module.shopCategoryItems;
    },
    links() {
      return [
        {
          text: this.$t("message.route-search"),
          route: `/store/${this.idStore}/category`,
          icon: mdiMagnify,
        },
        {
          text: this.$t("message.route-favorite"),
          route: `/store/${this.idStore}/wishlist`,
          icon: mdiHeartOutline,
        },
        {
          text: this.$t("message.route-history"),
          route: `/store/${this.idStore}/purchase-history`,
          icon: mdiCart,
        },
        {
          text: this.$t("message.route-store"),
          route: "/store",
          icon: mdiStore,
        },
      ];
    },
    isLength() {
      return this.$store.state.itemList_module.isLengthCategory;
    },
    categoryByStoreHeader() {
      return this.$store.state.itemList_module.categoryByStore;
    },
  },
  created() {
    this.dispatchStore();
    this.dispatchStoreCategoryItems();
  },
};
</script>

<style>
.message-no-item-category {
  width: 100%;
  height: 100px;
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent-style-category {
  width: 60%;
  padding: 4px;
}
.title-store {
  font-size: 16px;
  color: grey;
}
.title-category {
  background-color: #ededed;
  width: 100%;
  height: 65px;
}
.text_title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 20px;
  color: #424242;
}
</style>
